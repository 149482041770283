<template>
    <component :is="displayedComponent" />
</template>

<script>
    import api from '@/util/api';
    import { mapGetters } from 'vuex';

    export default {
        props: [],
        data() {
            return {
                loading: true,
                dynamicComponent: null,
            };
        },
        computed: {
            ...mapGetters(['availablePages']),
            displayedComponent() {
                if (this.loading) {
                    return {
                        template: '<h1>Loading...</h1>',
                    };
                }

                return this.dynamicComponent;
            },
        },
        methods: {
            checkPageAvailability(pageName) {
                const page = this.availablePages.find((page) => {
                    const pageTitle = page.page;

                    return pageTitle === pageName;
                });
                return Boolean(page);
            },
            async setDynamicComponent() {
                this.loading = true;
                const pageAvailable = this.checkPageAvailability(this.$route.params.pageName);

                if (!pageAvailable) {
                    this.dynamicComponent = { template: '<h1>Not Found :(</h1>' };
                    this.loading = false;
                } else {
                    try {
                        const res = await api.get(`/forms/${this.$route.params.pageName}`);

                        const methods = {};

                        for (const method in res.data.form_methods) {
                            const func = await import(
                                `@/util/dynamicFormFunctions/${res.data.form_methods[method].file}`
                            );
                            methods[method] = func[res.data.form_methods[method].function];
                        }

                        this.dynamicComponent = {
                            template: res.data.form_template,
                            data() {
                                return res.data.form_data;
                            },
                            methods,
                        };
                        this.loading = false;
                    } catch (err) {
                        console.error(err);
                    }
                }
            },
        },
        watch: {
            // Watching the $route object to update the dynamic form when the pageName is updated.
            $route(to, from) {
                if (to.params.pageName && from.params.pageName && to.params.pageName !== from.params.pageName) {
                    this.setDynamicComponent();
                }
            },
        },
        created() {
            this.setDynamicComponent();
        },
    };
</script>

<style></style>
